import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { BootstrapData, TravelBootstrapData } from '@/schema/bootstrap.schema';

export type AppEnv = 'production' | 'staging' | 'uat' | 'development' | null;
interface ConfigState {
  //
  bootstrapData: null | BootstrapData;
  updateBootstrapData: (bootstrapData: null | BootstrapData) => void;
  updateAppEnv: (appEnv: AppEnv) => void;
  //
  appEnv: AppEnv;
  // this property is used to control the home page redirection logic should be allowed or not
  shouldAllowHomeRedirect: boolean;
  updateShouldAllowHomeRedirect: (shouldAllowHomeRedirect: boolean) => void;
  //
  travelBootstrapData?: TravelBootstrapData;
  updateTravelBootstrapData: (travelBootstrapData: TravelBootstrapData) => void;
}

export const useCustomizationConfigStore = create<ConfigState>()(
  devtools(
    (set) => ({
      customizationConfig: null,
      // TODO: change to false once we support multiple icon style
      bootstrapData: null,
      appEnv: null,
      updateBootstrapData: (bootstrapData) => {
        return set((state) => {
          return { ...state, bootstrapData };
        });
      },
      updateAppEnv: (appEnv) => {
        return set((state) => {
          return { ...state, appEnv };
        });
      },
      updateTravelBootstrapData: (travelBootstrapData) => {
        return set((state) => {
          return { ...state, travelBootstrapData };
        });
      },
      shouldAllowHomeRedirect: false,
      updateShouldAllowHomeRedirect: (shouldAllowHomeRedirect) => {
        return set((state) => {
          return { ...state, shouldAllowHomeRedirect };
        });
      },
    }),
    { name: 'configStore' },
  ),
);

export function useBootstrapData() {
  const bootstrapData = useCustomizationConfigStore(
    (state) => state.bootstrapData,
  );
  return bootstrapData;
}

export function useTravelBootstrapData() {
  const travelBootstrapData = useCustomizationConfigStore(
    (state) => state.travelBootstrapData,
  );
  return travelBootstrapData;
}

export function useTenantId() {
  return useCustomizationConfigStore((state) => state.bootstrapData?.tenantId);
}

export function useIsEnableTermsAndConditionsModal() {
  const isEnableTermsAndConditionsModal = useCustomizationConfigStore(
    (state) => {
      return state.bootstrapData?.uiSettings.application.features
        .termsAndConditions?.enabled;
    },
  );
  return !!isEnableTermsAndConditionsModal;
}

export function useAppEnv() {
  return useCustomizationConfigStore((state) => state.appEnv);
}

export function useShouldAllowHomeRedirect() {
  return useCustomizationConfigStore((state) => state.shouldAllowHomeRedirect);
}
