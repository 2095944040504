import { createSharedPathnamesNavigation } from 'next-intl/navigation';
export {
  ReadonlyURLSearchParams,
  RedirectType,
  ServerInsertedHTMLContext,
  notFound,
  useParams,
  useSearchParams,
  useSelectedLayoutSegment,
  useSelectedLayoutSegments,
  useServerInsertedHTML,
} from 'next/navigation';

/**
 * NOTE: This is required for pathnames that are not locale-aware, such as [/auth/apple] and [auth redirection].
 *
 * Read this [PR] for more information.
 *
 * [/auth/apple]: https://github.com/Kaligo/devops/blob/605776413902c1555227c7e8bebbdc99a016ac27/terraform/families/RC/files/rc-gw-test.yaml#L242-L244
 * [auth redirection]: https://github.com/Kaligo/rewards-central/blob/98a398dd35230081d1b8fb3cceb7b4828c05e3c7/apps/web/src/app/auth-provider.tsx#L168
 * [PR]: https://github.com/Kaligo/devops/pull/7202
 */
export { useRouter as useRouterWithoutLocalePrefix } from 'next/navigation';

import { supportedLocales } from './constants';

export const { Link, permanentRedirect, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({
    locales: supportedLocales,
  });
